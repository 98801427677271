<template>
  <b-modal
    size="xlg"
    ref="modalChecks"
    @hidden="$emit('hidden')"
    hide-footer
    body-class="p-0"
    scrollable
    title="CHECKBOOK CHECKS"
  >
    <filter-slot
            :no-visible-principal-filter="true"
            :filter="filter"
            :filter-principal="filterPrincipal"
            :total-rows="totalRows"
            :paginate="paginate"
            :start-page="paginate.startPage"
            :to-page="paginate.toPage"
            @reload="$refs['checksTable'].refresh()"
            >
      <template #table>
        <b-table
          :fields="fieldsChecks"
          :items="myProvider"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy-sync="isBusy"
          show-empty
          empty-text="No checks to view..."
          ref="checksTable"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>
<script>
import bankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
export default {
  data() {
    return {
      fieldsChecks: [
        {
          key: "check_number",
          label: "CHECK NUMBER",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true,
        },
        {
          key: "amount",
          thClass: "text-nowrap",
          tdClass: (value) => (value < 0 ? "text-danger text-nowrap" : "text-success text-nowrap"),
          visible: true,
          formatter: (value) =>
            this.$options.filters.myMoney(
              this.$options.filters.currency(value)
            ),
        },
        {
          key: "created_by",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true,
        },
        {
          key: "code",
          label: "OFFER PAYMENT",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true,
        },
        {
          key: "memo",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true,
        },
        {
          key: "charged",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "created_at",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true,
          formatter: (value) => this.$options.filters.myGlobalDay(value),
        },
      ],
      isBusy: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
      totalRows:0,
      searchInput: "",
      sortBy: "",
      sortDesc: true,
      filterPrincipal: {
      },
      filter:[],
    };
  },
  props: {
    paramsChecks: {
      type: Object,
    },
  },
  methods: {
    async myProvider() {
      let params={
        checkbook_id: this.paramsChecks.checkbook_id,
        npage: this.paginate.currentPage,
        perPage: this.paginate.perPage
      }
      const {data} = await bankAccountsService.getChecksByCheckbookId(params);
      this.totalRows = data.total;
          if (data.from) {
            this.paginate.startPage = data.from;
          }else{
            this.paginate.startPage = 0
          }
          if (data.to) {
            this.paginate.toPage = data.to;
          }else{
            this.paginate.toPage = 0
          }
          return data.data || [];
      },
  },
  mounted() {
    this.toggleModal("modalChecks");
  },
};
</script>