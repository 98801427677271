<template>
  <b-modal
    ref="modalCommentChangeStatus"
    @hidden="$emit('hidden')"
    no-close-on-backdrop
  >
    <!-- THE NAME OF THE ACCOUNT IS SHOWN NEXT TO THE TITLE OF THE FASHION, TO KNOW WHICH ACCOUNT IS GOING TO MODIFY ITS STATUS -->
    <template #modal-title> CHANGE STATUS TO: {{ title }} </template>

    <validation-observer ref="form">
      <validation-provider v-slot="{ errors }" rules="required">
        <b-form-group class="mt-1" label="Enter a comment for the status change">
        <b-form-textarea
          ref="textAreaComment"
          v-model="commentText"
          placeholder="Enter a comment..."
          rows="3"
          max-rows="6"
          :class="errors[0]"
          autofocus
          maxlength="254"
        >
        </b-form-textarea>
        </b-form-group>
      </validation-provider>
    </validation-observer>

    <template #modal-footer>
      <!-- Emit an event called 'hidden' when the CANCEL button is clicked -->
      <b-button @click="$emit('hidden')" variant="secondary"> CANCEL </b-button>

      <!-- Call the changeStatusBankAccount() method when the SAVE button is clicked -->
      <b-button @click="changeStatusBankAccount()" variant="primary">
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import bankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "is-invalid",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      commentText: "",
    };
  },
  props: {
    title: {
      type: String,
    },
    paramsChangeStatusBankAccount: {
      type: Object,
    },
  },
  methods: {
    // This function is used to change the status of a bank account
    async changeStatusBankAccount() {
      // Validate the form before proceeding
      if (await this.$refs.form.validate()) {
        // Assign the comment text to the paramsChangeStatusBankAccount object
        this.paramsChangeStatusBankAccount.comment = this.commentText;
        try {
          // Show a confirmation swal
          const result = await this.showConfirmSwal();
          // If the user confirms, proceed with the request
          if (result.isConfirmed) {
            this.addPreloader()
            // Make an API call to change the status of the bank account
            const data = await bankAccountsService.changeStatusBankAccount(
              this.paramsChangeStatusBankAccount
            );
            // Check if there is a message in the response
            if (data.data[0]) {
              // Show the message in an info swal
              this.showInfoSwal(data.data[0].message);
            } else {
              // If the status code is 200, emit an event and show a success swal
              if (data.status == 200) {
                this.$emit("onChangeStatus");
                this.showSuccessSwal("Status changed successfully");
              }
            }
          }
        } catch (e) {
          // Show an error swal if an exception occurs
          this.showErrorSwal(e);
        }finally{
          this.removePreloader()
        }
      }
    },
  },
  mounted() {
    this.toggleModal("modalCommentChangeStatus");
  },
};
</script>