<template>
  <b-modal
    scrollable
    size="xlg"
    ref="modalTrackingBankAccount"
    @hidden="$emit('hidden')"
    hide-footer
    body-class="p-0"
  >
    <template #modal-title> VIEW TRACKING OF: {{ title }} </template>
    <b-table :items="paramsTrackingBankAccount" :fields="fields" show-empty empty-text="No records to show... ">
      <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
      <template #cell(status)="data">
        <b-badge
          class="w-100 let"
          pill
          :variant="variantBadgeStatus(data.item.status)"
          style="text-transform: uppercase;"
        >
        <span class="m-1">{{ data.item.status }}</span>
        </b-badge>
      </template>
      <template #cell(comment)="data">
        <ChangeSms v-if="data.item.comment" :SmsData="data.item.comment"/>
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | myGlobalDay }}
      </template>

      <template #cell(updated_at)="data">
        {{ data.item.updated_at | myGlobalDay }}
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue"
export default {
  components:{
    ChangeSms
  },
  data() {
    return {
      fields: [
        {
          key: "user",
          label: "CREATED BY",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          key: "comment",
          thClass: "text-nowrap",
        },
        {
          key: "status",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
        },
        {
          key: "created_at",
          label: "CREATED AT",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
        },
        {
          key: "updated_at",
          label: "UPDATED AT",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
        },
      ],
    };
  },
  props: {
    title: {
      type: String,
    },
    paramsTrackingBankAccount: {
      type: Array,
    },
  },
  mounted() {
    this.toggleModal("modalTrackingBankAccount");
  },
  computed:{
    obtainss(){
      return (val)=> function(){
        return 'asdasd'+val
      }
    }
  },
  methods: {
    showDetails(comment){
      return comment.substring(0,10)
    },
    // This function takes a status and returns a badge variant based on the status
    variantBadgeStatus(status) {
      if (status == "active") {
        return "success";
      } else {
        return "secondary";
      }
    },
  },
};
</script>

