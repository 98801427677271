<template>
  <div>
    <!-- buttonAllScreens -->
    <portal to="actions_header">
      <b-button
        variant="outline-success"
        ref="createBankAccount"
        @click="showModal = true"
      >
        <feather-icon icon="PlusIcon" />
        CREATE
      </b-button>
    </portal>
    <!-- button SM -->
    <portal to="actionsButtons_header">
      <b-button
        variant="outline-success"
        ref="createBankAccount"
        @click="showModal = true"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </portal>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['bankAccountsTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          :per-page="paginate.perPage"
          ref="bankAccountsTable"
          :current-page="paginate.currentPage"
          no-border-collapse
          show-empty
          sticky-header="50vh"
          class="position-relative"
          table-class="text-nowrap"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="myProvider"
          :filter="searchInput"
          :fields="visibleFields"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(account_name)="data">
            <span>
              {{ data.item.account_name }}
            </span>
            <br />
            <span>
              <small v-b-tooltip.hover.right="'Routing number'">
                {{ data.item.routing_number }}
              </small>
            </span>
          </template>

          <template #cell(account_number)="data">
            <span>
              {{ data.item.bank_name }}
            </span>
            <br />
            <span>
              <small v-b-tooltip.hover.right="'Account number'">
                {{ data.item.account_number }}
              </small>
            </span>
          </template>

          <template #cell(address)="data">
            <span v-b-tooltip.hover.right="data.item.address">
              <change-sms :sms-data="data.item.address" :text-length="20" :variant="'info'" type-show-more="span" />
            </span>
            <small>
              {{ data.item.state }}
            </small>
          </template>

          <template #cell(checkbooks_count)="data">
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ data.item.checkbooks_count }}</span>
              <span>
                <feather-icon
                  v-if="data.item.checkbooks_count"
                  v-b-tooltip.hover.top="'View checkbooks'"
                  icon="ExternalLinkIcon"
                  size="20"
                  class="cursor-pointer text-danger mr-2 ml-1"
                  @click="
                    openModalViewCheckbooks(
                      data.item.id,
                      data.item.account_name
                    )
                  "
                />
              </span>
            </div>
          </template>
          <template #cell(balance)="data">
            <span
              v-if="data.item.balance"
              :class="data.item.balance > 0 ? 'text-success' : 'text-danger'"
              class="cursor-pointer"
              @click="ModalTrackingBalance(data.item)"
            >
              $ {{ data.item.balance | currency }}
            </span>
            <span v-if="!data.item.balance"> -</span>
          </template>

          <template #cell(freezed_amount)="data">
            <span
              v-if="data.item.freezed_amount"
              :class="
                data.item.freezed_amount > 0 ? 'text-primary' : 'text-primary'
              "
              class="cursor-pointer"
              @click="openModalFrezeBalance(data.item.id)"
            >
              $ {{ data.item.freezed_amount | currency }}
            </span>
            <span v-if="!data.item.balance"> -</span>
          </template>
          <template #cell(available_balance)="data">
            <span
              :class="
                data.item.available_balance > 0 ? 'text-success' : 'text-danger'
              "
            >
              $ {{ data.item.available_balance | currency }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-badge
              class="w-100"
              pill
              :variant="data.item.status == 'active' ? 'success' : 'secondary'"
            >
              {{ data.item.status | myFontCapitalize }}
            </b-badge>
          </template>
          <template #cell(tracking)="data">
            <feather-icon
              v-b-tooltip.hover.top="'Tracking'"
              icon="ListIcon"
              size="20"
              class="cursor-pointer text-primary mr-2 ml-1"
              @click="
                openModalTrackingBankAccount(
                  data.item.id,
                  data.item.account_name
                )
              "
            />
          </template>
          <template #cell(actions)="data">
            <feather-icon
              v-b-tooltip.hover.top="
                data.item.status == 'active'
                  ? 'Desactivate Account'
                  : 'Activate Account'
              "
              :icon="
                data.item.status == 'active' ? 'ThumbsDownIcon' : 'ThumbsUpIcon'
              "
              size="20"
              class="cursor-pointer mr-1"
              :class="
                data.item.status == 'active' ? 'text-danger' : 'text-success'
              "
              @click="
                openModalCommentChangeStatus(
                  data.item.status == 'active' ? 2 : 1,
                  data.item.id,
                  data.item.account_name,
                  data.item.type_code
                )
              "
            />
            <feather-icon
              v-b-tooltip.hover.top="'Edit Account'"
              icon="EditIcon"
              size="18"
              class="cursor-pointer text-warning mr-1"
              @click="showAccount(data.item)"
            />
            <feather-icon
              v-b-tooltip.hover.top="'Update Account Balance'"
              icon="TrendingUpIcon"
              size="18"
              class="cursor-pointer mr-1"
              @click="showModalUpdateBalance(data.item)"
            />
            <feather-icon
              v-if="
                deleteOrNot == 1 &&
                data.item.has_pending_order == 0 &&
                data.item.freezed_amount == 0
              "
              v-b-tooltip.hover.top="'Delete Account'"
              icon="TrashIcon"
              size="18"
              class="cursor-pointer text-danger"
              @click="deleteAccount(data.item.id)"
            />
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.full_name_creator }}
            </span>
            <br />
            <span>
              {{ data.item.created_at | myGlobalDay }}
            </span>
          </template>
          <template #cell(deleted_at)="data">
            <span>
              {{ data.item.full_name_deletor }}
            </span>
            <br />
            {{ data.item.deleted_at | myGlobalDay }}
          </template>
        </b-table>
      </template>
    </filter-slot>
    <ModalCommentChangeStatus
      v-if="showModalComment"
      :title="titleAccountName"
      :paramsChangeStatusBankAccount="paramsChangeStatus"
      @hidden="showModalComment = false"
      @onChangeStatus="hideAndRefreshModal"
    />
    <ModalCheckbooks
      v-if="showModalChecbooks"
      :paramsCheckbooks="paramsCheckbooks"
      @hidden="showModalChecbooks = false"
      @reloadCheckbook="$refs.bankAccountsTable.refresh()"
    />
    <ModalTrackingBankAccount
      v-if="showModalTrackingBankAccount"
      :title="titleAccountName"
      :paramsTrackingBankAccount="paramsTrackingBankAccount"
      @hidden="showModalTrackingBankAccount = false"
    />

    <ModalUpdateBalance
      v-if="showModalBalanceUpdate"
      @hidden="showModalBalanceUpdate = false"
      @closeModalUpdateBalance="closeModalUpdateBalance"
      :dataBalance="dataBalance"
      @onCreateAccount="refreshTable"
      @onUpdateAccount="onUpdateAccount"
    />

    <ModalCreationCheckbooks
      v-if="showModalCreationCheckbooks"
      @hidden="showModalCreationCheckbooks = false"
      @closeModalCreationCheckbooks="closeModalCreationCheckbooks"
      :dataCheckbooks="dataCheckbooks"
    />

    <ModalBankAccounts
      v-if="showModal"
      @hidden="closeModalBankAccounts"
      @onCreateAccount="refreshTable"
      @onUpdateAccount="hideAndRefreshModal"
      :dataAccount="dataUpdate"
      :isUpdate="isUpdate"
      @closeModal="closeModalBankAccounts"
    />

    <ModalCreateCheckbooks
      v-if="showModalCreateCheckbook"
      :idBankAccount="dataBalance"
      @hidden="showModalCreateCheckbook = false"
      @closeModalCheck="closeModalCreateCheckbooks"
    />

    <ModalTrackingBalanceBanckAccount
      :idTrackinBankAccount="idBankAcountTracking"
      v-if="showModalTrackingBalance"
      @hidden="showModalTrackingBalance = false"
    />

    <ModalFrezeBalance
      v-if="showModalFrezeBalance"
      :bankAccountId="bankAccountId"
      @hidden="closeModalFreze"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import bankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import FiltersData from "@/views/debt-solution/views/bank-account/data/filters.data";
import Fields from "@/views/debt-solution/views/bank-account/data/fields.data";
import ModalCommentChangeStatus from "@/views/debt-solution/views/bank-account/components/ModalCommentChangeStatus.vue";
import ModalCheckbooks from "@/views/debt-solution/views/bank-account/components/ModalCheckbooks.vue";
import ModalTrackingBankAccount from "@/views/debt-solution/views/bank-account/components/ModalTrackingBankAccount.vue";
import ModalBankAccounts from "@/views/debt-solution/views/bank-account/components/ModalBankAccounts.vue";
import ModalCreationCheckbooks from "@/views/debt-solution/views/bank-account/components/ModalCreationCheckbooks.vue";
import ModalUpdateBalance from "@/views/debt-solution/views/bank-account/components/ModalUpdateBalance.vue";
import ModalTrackingBalanceBanckAccount from "@/views/debt-solution/views/bank-account/components/ModalTrackingBalanceBanckAccount.vue";
import ModalFrezeBalance from "@/views/debt-solution/views/bank-account/components/ModalFrezeBalance.vue";
import ChangeSms from '@/views/commons/components/clients/components/clientsSms/ChangeSms.vue'
export default {
  components: {
    ModalCommentChangeStatus,
    ModalCheckbooks,
    ModalBankAccounts,
    ModalUpdateBalance,
    ModalCreationCheckbooks,
    ModalTrackingBalanceBanckAccount,
    ModalTrackingBankAccount,
    ModalFrezeBalance,
    ChangeSms
  },

  data() {
    return {
      bankAccountId: 0,
      showModalComment: false,
      showModalFrezeBalance: false,
      showModal: false,
      showModalCreationCheckbooks: false,
      showModalBalanceUpdate: false,
      showModalTrackingBalance: false,
      dataUpdate: {
        bankName: "",
        accountName: "",
        routingNumber: "",
        accountNumber: "",
        checkNumber: "",
        balance: "",
        statusAccount: "",
        type: "",
        typeCheckbook: 1,
        id: 0,
      },
      idBankAcountTracking: 0,
      dataBalance: {
        balance: 0,
        idBankAcount: 0,
        typeAccount: "",
        statusAccount: "",
        accountName: "",
      },
      dataCheckbooks: {
        idBankAcount: 0,
      },
      isUpdate: false,
      paramsChangeStatus: {},
      comment: "",
      paramsCheckbooks: [],
      paramsTrackingBankAccount: [],
      titleAccountName: "",
      showModalChecbooks: false,
      showModalCreateCheckbook: false,
      showModalTrackingBankAccount: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "",
      sortDesc: true,

      searchInput: "",
      totalRows: 0,
      fields: Fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: FiltersData,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      deleteOrNot: 1,
    };
  },
  methods: {
    alternarTextoCompleto(row) {
      let textoCompleto = row.address;
      let textoCortado = this.cortarTexto(row.address);
      
      if (row.mostrarCompleto) {
        row.mostrarCompleto = false;
        row.address = textoCortado;
      } else {
        row.mostrarCompleto = true;
        row.address = textoCompleto;
      }
    },
    cortarTexto(textoLargo) {
      if (textoLargo.length <= 30) {
        return textoLargo;
      } else {
        var textoCortado = textoLargo.slice(0, 30) + "...";
        return textoCortado;
      }
    },
    // myProvider function for fetching bank account information
    myProvider: async function (ctx) {
      // try peforming a request
      try {
        // define params object with query data
        let params = {
          order_by: ctx.sortBy || "status",
          orden: !ctx.sortDesc ? "desc" : "asc",
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          status: this.filter[4].model,
          campo: this.filterPrincipal.model,
          type: this.filter[5].model,
          balanceRangeFrom:
            this.filter[2].model === 0 ? null : this.filter[2].model,
          balanceRangeTo:
            this.filter[3].model === 0 ? null : this.filter[3].model,
          dateRangeFrom: this.filter[0].model,
          dateRangeTo: this.filter[1].model,
          deletedOrNot: this.deleteOrNot,
        };
        // perform service call to get the bank accounts based on the params
        const { data, status } =
          await bankAccountsService.getBankAccountsPaginated(params);
        // check for valid response
        if (status == 200) {
          // assign totalRows from data response
          this.totalRows = data.total;
          // assign startPage from data or default
          this.startPage = data.from ? data.from : 0;
          // assign toPage from data of default
          this.toPage = data.to ? data.to : 0;
          // return the data array
          return data.data || [];
        }
      } catch (error) {
        // log any errors
        console.error(error);
        // return empty array by default on error
        return [];
      }
    },
    closeModalBankAccounts() {
      this.showModal = false;
      this.isUpdate = false;
    },
    closeModalUpdateBalance() {
      this.showModalBalanceUpdate = false;
    },
    closeModalFreze() {
      this.showModalFrezeBalance = false;
    },
    // Function to show account details
    showAccount(params) {
      // Set isUpdate flag to true
      this.isUpdate = true;
      // Show modal window
      this.showModal = true;
      // Store data for update
      this.dataUpdate = params;
    },
    // Function to show the modal for updating balance
    ModalCreationCheckbooks(params) {
      this.showModalCreationCheckbooks = true;
      this.dataCheckbooks.idBankAcount = params.id;
    },
    closeModalCreationCheckbooks() {
      this.showModalCreationCheckbooks = false;
    },
    showModalUpdateBalance(params) {
      // Set showModalBalanceUpdate to true to display the modal
      this.showModalBalanceUpdate = true;
      // Assign the available balance, id, type, status and account name from params to dataBalance
      (this.dataBalance.available_balance = params.available_balance),
        (this.dataBalance.idBankAcount = params.id),
        (this.dataBalance.typeAccount = params.type),
        (this.dataBalance.statusAccount = params.status),
        (this.dataBalance.accountName = params.account_name);
    },
    ModalTrackingBalance(params) {
      this.showModalTrackingBalance = true;
      this.idBankAcountTracking = params.id;
    },
    openModalFrezeBalance(params) {
      this.bankAccountId = params;
      this.showModalFrezeBalance = true;
    },
    onUpdateAccount() {
      this.showModalBalanceUpdate = false;
      this.hideAndRefreshModal();
    },

    openModalCommentChangeStatus(status, bank_account_id, account_name, type) {
      this.paramsChangeStatus = {
        bank_account_id: bank_account_id,
        status: status,
        comment: "",
        user_id: this.currentUser.user_id,
        type: type,
      };
      this.titleAccountName = account_name;
      this.showModalComment = true;
    },
    async openModalViewCheckbooks(id_bank_account, account_name) {
      this.paramsCheckbooks = {
        title: account_name,
        id_bank_account: id_bank_account,
      };
      this.showModalChecbooks = true;
    },
    async openModalTrackingBankAccount(id_bank_account, account_name) {
      this.titleAccountName = account_name;
      const data = await bankAccountsService.getTrackingsBankAccount({
        bank_account_id: id_bank_account,
      });
      this.paramsTrackingBankAccount = data.data;
      this.showModalTrackingBankAccount = true;
    },
    hideAndRefreshModal() {
      this.showModalComment = false;
      this.showModal = false;
      this.isUpdate = false
      this.$refs["bankAccountsTable"].refresh();
    },
    // This function resets all filters to their default values
    resetAllFilters() {
      // Iterate through each filter and set its model value to null
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      // Set the principal filter's model value to null
      this.filterPrincipal.model = null;
    },
    refreshTable() {
      this.$refs["bankAccountsTable"].refresh();
      this.showModal = false;
      this.showModalBalanceUpdate = false;
    },
    // deleteAccount function to delete a bank account
    refreshTableTrackingCheck() {
      this.$refs["trackingTable"].refresh();
    },
    async deleteAccount(idAccount) {
      // set params for deletion
      let params = {
        bank_account_id: idAccount,
        user_id: this.currentUser.user_id,
      };
      try {
        // show confirmation swal
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          // call service to delete the bank account
          const { status } =
            await bankAccountsService.deletionLogicalBankAccount(params);
          if (status == 200) {
            // show success swal
            this.showSuccessSwal("Bank Account Deleted Successfully");
            // refresh table
            this.refreshTable();
          }
        }
      } catch (error) {
        // check for error response
        if (error.response?.data?.error) {
          // show info swal
          this.showInfoSwal(error.response?.data?.error, "Info");
        } else {
          // show error swal
          this.showErrorSwal(error);
        }
      } finally {
        this.removePreloader();
      }
    },
    shortText(text){

      if (text.length <= 20) {
        return text;
      } else {
        return text.slice(0, 20) + "...";
      }

    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  mounted() {
    // Check if the route name is "ds-bank-accounts-table-deleted"
    if (this.$route.name === "debt-solution-settings-bank-accounts-deleted") {
      // Set deleteOrNot to 2
      this.deleteOrNot = 2;
      // Make field 6 and 7 visible
      this.fields[6].visible = false;
      this.fields[7].visible = false;
      // Make fields 10 invisible
      this.fields[10].visible = true;
      // Make field 12 visible
      this.fields[11].visible = false;
      this.fields[12].visible = false;
    } else {
      // Make field 6 and 7 visible
      this.fields[6].visible = true;
      this.fields[7].visible = true;
      // Make fields 10 invisible
      this.fields[10].visible = false;
      // Make field 11 visible
      this.fields[11].visible = true;
      this.fields[12].visible = true;
    }
  },
};
</script>