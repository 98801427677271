<template>
  <div>
    <b-modal
      size="xl"
      ref="modalCheckbooks"
      @hidden="$emit('hidden')"
      hide-footer
      body-class="p-0"
    >
      <template #modal-title> VIEW CHECKBOOKS OF: {{ paramsCheckbooks.title }} </template>
      <b-row>
        <b-col class="content-header-right text-md-right">
          <b-button v-if="deleteOrNot == 0" variant="success" class="m-2"  @click="ModalCreationCheckbooks = true"> + CREATE </b-button>
        </b-col>
      </b-row>
      <div class="p-2">
        <b-nav card-header pills class="m-0">
          <b-nav-item @click="$refs['CheckbooksTable'].refresh(), deleteOrNot = 0" :link-classes="[deleteOrNot == 0 ? 'active':'','px-3',bgTabsNavs]">
            CHECKBOOKS
          </b-nav-item>
          <b-nav-item @click="$refs['CheckbooksTable'].refresh(), deleteOrNot = 1" :link-classes="[deleteOrNot == 1 ? 'active' : '','px-3',bgTabsNavs]">
            DELETED
          </b-nav-item>
        </b-nav>
        <b-card no-body
        class="border-top-primary border-3 border-table-radius px-0">
        <b-table
          ref="CheckbooksTable"
          :fields="visibleFieldsCheckbook" :items="myProvider"
          sticky-header="50vh"
          class="position-relative"
          table-class="text-nowrap"
          show-empty
         


          >
         <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          
          <template #cell(status)="data">
            <b-badge class="w-100" pill :variant="data.item.status == 'Active' ? 'primary' : 'secondary'">
              {{data.item.status | myFontCapitalize}}
            </b-badge>
          </template>
          <template #cell(used_checks)="data">
            <b-badge class="w-100" pill :variant="data.item.used_checks == 0 ? 'secondary' : 'primary'">
              <span v-b-tooltip.hover.top="'View checks'" class="cursor-pointer" @click="openModalChecks(data.item.id)"> {{data.item.used_checks}} </span>
              </b-badge>
              
          </template>
          <template #cell(type)="data">
            <b-badge class="w-100" pill :variant="data.item.type == 'VIRTUAL' ? 'primary' : 'secondary'">
              {{ data.item.type }}
            </b-badge>
          </template>type

          <template #cell(actions)="data">
            <feather-icon
              v-if="data.item.used_checks == 0 && data.item.type == 'PHYSICAL'"
              v-b-tooltip.hover.top=" data.item.status == 'Active' ? 'Deactivate Checkbook' : 'Activate Checkbook'"
              :icon=" data.item.status == 'Active' ? 'ThumbsDownIcon' : 'ThumbsUpIcon' "
              size="20"
              class="cursor-pointer mr-1 "
              :class="data.item.status == 'Active' ? 'text-danger' : 'text-success'"
              @click="changeStatusCheckbook(data.item.id, (data.item.status == 'Active' ? 2 : 1))"
            />

            <feather-icon
              v-if="data.item.used_checks == 0 && data.item.type == 'PHYSICAL'"
              v-b-tooltip.hover.top="'EDIT CHECKBOOK'"
              icon="EditIcon"
              size="20"
              class="cursor-pointer mr-1 text-warning"
              @click="openModalUpdateCheckbook(data.item.id)"
            />
            
            <feather-icon
              v-if="data.item.used_checks == 0 && data.item.type == 'PHYSICAL'"
              v-b-tooltip.hover.top="'DELETE CHECKBOOK'"
              icon="TrashIcon"
              size="20"
              class="cursor-pointer text-danger"
              @click="deletionLogicalCheckbook(data.item.id)"
            />
          </template>
        </b-table>
        </b-card>
      </div>
    </b-modal>
  <ModalCreationCheckbooks
        v-if="ModalCreationCheckbooks"
        @hidden="ModalCreationCheckbooks = false, isUpdate = false"
         :dataCheckbooks="paramsCheckbooks.id_bank_account"
         :isUpdate="isUpdate"
         :idCheckbook="idCheckbook"
        @closeModalCreationCheckbooks="ModalCreationCheckbooks = false, isUpdate = false"
        @reloadCheckbooksTable="reloadCheckbooksTable"
  />
    <ModalChecks v-if="showModalChecks" :paramsChecks="paramsChecks" @hidden="showModalChecks = false"/>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import bankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import ModalChecks from "@/views/debt-solution/views/bank-account/components/checkbooks/ModalChecks.vue"
import ModalCreationCheckbooks from "@/views/debt-solution/views/bank-account/components/ModalCreationCheckbooks.vue";
export default {
  components:{
    ModalChecks,
    ModalCreationCheckbooks
  },
  data() {
    return {
      isBusy: false,
      ModalCreationCheckbooks: false,
      showModalChecks: false,
      fieldsCheckbook: [
        {
          key: "created_by",
          label: "CREATED BY",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
          visible: true
        },
        {
          key: "number",
          label: "NUMBER",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
          visible: true
        },
        {
          key: "type",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
          visible: true
        },
        {
          key: "created_at",
          label: "CREATED AT",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
          visible: true,
          formatter: (value) => this.$options.filters.myGlobalDay(value)
        },
        {
          key: "deleted_by",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true
        },
        {
          key: "deleted_at",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-center",
          visible: true,
          formatter: (value) => this.$options.filters.myGlobalDay(value)
        },
        {
          key: "status",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
          visible: true
        },
        {
          key: "range_checks",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true
        },
        {
          key: "used_checks",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true
        },
        {
          key: "actions",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          visible: true
        }
      ],
      paramsChecks:[],
      data:[],
      deleteOrNot:0,
      isUpdate: false,
      idCheckbook: 0
    };
  },
  props: {
    paramsCheckbooks: {
      type: Object,
    },
  },
  methods: {
    openModalChecks(checkbook_id){
      this.showModalChecks = true
      this.paramsChecks={
        checkbook_id: checkbook_id,
      }
    },
// GET CHECKBOOKS BY BANK ACCOUNT ID
    async myProvider(){
      try{
        const data = await bankAccountsService.getCheckbooksByBankAccount({
          bank_account_id: this.paramsCheckbooks.id_bank_account,
          deleted_or_not: this.deleteOrNot
        });
        return data.data || [];
      }catch(error){
        throw error
      }
    },
// CHANGE STATUS FOR CHECKBOOK
    async changeStatusCheckbook(id_checkbook, status) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader()
          const data = await bankAccountsService.changeStatusCheckbook({
            _checkbook_id: id_checkbook,
            _status: status,
          });
          if (data.status == 200) {
            this.$refs['CheckbooksTable'].refresh()
            this.showSuccessSwal("Success!","Status changed successfully");
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }finally{
        this.removePreloader()
      }
    },
    // UPDATE CHECKBOOK
    openModalUpdateCheckbook(id_checkbook){
      this.isUpdate = true
      this.idCheckbook = id_checkbook
      this.ModalCreationCheckbooks = true
    },
    // DELETION LOGICAL FOR CHECKBOOK
    async deletionLogicalCheckbook(id_checkbook){
      try {
        let params={
          id_checkbook: id_checkbook,
          id_user: this.currentUser.user_id,
        }
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader()
          const {status} = await bankAccountsService.deletionLogicalCheckbook(params)
          if(status == 200){
            this.showSuccessSwal('Success!','The checkbook has been successfully deleted')
            this.$refs['CheckbooksTable'].refresh()
          }
        }
      } catch (error) {
        if(error.response?.data?.error){
            this.showInfoSwal(error.response?.data?.error, 'Info' );
          }else{
            this.showErrorSwal(error);
          }
      }finally{
        this.removePreloader()
      }
    },

    //RELOAD THE GRID
    reloadCheckbooksTable(){
      this.$refs['CheckbooksTable'].refresh()
      this.$emit('reloadCheckbook')
    }
  },
  computed:{
    visibleFieldsCheckbook(){
      if(this.deleteOrNot === 0){
        this.fieldsCheckbook[4].visible = false
        this.fieldsCheckbook[5].visible = false
        this.fieldsCheckbook[6].visible = true
        this.fieldsCheckbook[9].visible = true
      }else{
        this.fieldsCheckbook[4].visible = true
        this.fieldsCheckbook[5].visible = true
        this.fieldsCheckbook[6].visible = false
        this.fieldsCheckbook[9].visible = false
      }
      return this.fieldsCheckbook.filter(field=>field.visible)
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalCheckbooks");
    if(this.$route.name === 'm-bank-accounts-table-deleted' ){
      this.fieldsCheckbook[9].visible = false
    }
  },
};
</script>