<template>
  <div>
    <b-modal
      size="lg"
      body-class="p-0"
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-update-balance"
      id="modal-1"
      title="UPDATE BALANCE"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <b-form-group label="Available balance">
                <money
                  disabled="disabled"
                  v-model="dataBalance.available_balance"
                  v-bind="money"
                  type="text"
                  class="form-control text-center rounded text-primary border-primary"
                >
                </money>
              </b-form-group>
            </div>
            <div class="form-group col-md-6">
              <b-form-group label="Account name">
                <b-form-input
                  disabled="disabled"
                  type="text"
                  class="form-control text-center rounded text-primary border-primary"
                  v-model="dataBalance.accountName"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|validate-amount"
                name="balance"
              >
                <b-form-group label="Amount">
                  <money
                    v-model="balance"
                    v-bind="money"
                    :class="{
                      'is-invalid': errors[0] && validateMoney,
                    }"
                    class="form-control"
                  >
                  </money>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="type"
              >
                <b-form-group :class="errors[0]" label="Action">
                  <v-select
                    v-model="typeAction"
                    :options="typeBalance"
                    :reduce="(item) => item.id"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                :rules="typeAction == 2 ? 'required' : ''"
              >
                <b-form-group :class="errors[0]" label="Enter a reason...">
                  <b-form-textarea
                    ref="textAreaComment"
                    v-model="commentText"
                    placeholder="Enter a reason..."
                    rows="3"
                    max-rows="6"
                    maxlength="254"
                    :class="errors[0]"
                  >
                  </b-form-textarea>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button class="" @click="cancel()">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="updateBalance"
            ><feather-icon icon="SaveIcon" class="mr-1" />UPDATE</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import bankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import vSelect from "vue-select";
import { Money } from "v-money";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    Money,
  },
  directives: { money: Money },
  props: {
    dataBalance: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getNewBalance(){
      let newBalance = 0
      if(this.typeAction == 1){
        newBalance = parseFloat(this.dataBalance.available_balance) + parseFloat(this.balance)
      }else if(this.typeAction == 2){
        newBalance = parseFloat(this.dataBalance.available_balance) - parseFloat(this.balance)
      }
      return 'The new balance for the bank account: '+this.dataBalance.accountName+' is: $'+ this.$options.filters.currency(newBalance.toFixed(2))
    }
  },
  mounted() {
    this.toggleModal("modal-update-balance");
  },
  data() {
    return {
      validateMoney: false,
      directives: { money: Money },
      typeBalance: [
        { id: 1, label: "Deposit" },
        { id: 2, label: "Withdrawal" },
      ],
      balance: "",
      typeAction: "",
      money: {
        decimal: ",",
        prefix: "$ ",
        thousands: ".",
        precision: 2,
        maxlength: 16,
      },
      commentText: "",
    };
  },
  methods: {
    // This function is used to update the balance of a bank account
    async updateBalance() {
      // Validate the observer
      const result = await this.$refs.observer.validate();
      // Set validateMoney to true
      this.validateMoney = true;
      // If the validation is successful
      if (result) {
        try {
          // Create an object with the parameters needed for the update
          const params = {
            idBankAccount: this.dataBalance.idBankAcount,
            typeAction: this.typeAction,
            balance: parseFloat(this.balance),
            user_id: this.currentUser.user_id,
            typeAccount: this.dataBalance.typeAccount,
            statusAccount: this.dataBalance.statusAccount,
            comment: this.commentText,
          };
          // Show a confirmation swal
          const resultState = await this.showConfirmSwal('Are you sure?', 'The bank account balance will be updated.');
          // If the user confirms the action
          if (resultState.isConfirmed) {
            // Add a preloader
            this.addPreloader();
            // Call the service to update the balance
            const resul = await bankAccountsService.updateBalanceBankAccount(
              params
            );
            // If the request was successful
            if (resul.status == 200) {
              // Show a success swal
              this.showSuccessSwal('The balance of the bank account has been updated successfully', this.getNewBalance);
              // Emit an event to update the account
              this.$emit("onUpdateAccount");
            }
          }
        } catch (error) {
          // If there is an error show a warning swal
          if (error.response?.data?.error) {
            this.showWarningSwal(
              error.response?.data?.error,
              "The balance is insufficient in the account"
            );
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          // Remove the preloader
          this.removePreloader();
        }
      }
    },
    cancel() {
      this.$emit("closeModalUpdateBalance");
    },
  },
};
</script>