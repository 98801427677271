<template>
  <div>
    <b-modal
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-add-checkbooks"
      size="lg"
      :title="isUpdate == true ? 'UPDATE CHECKBOOK' : 'ADD CHECKBOOK'"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="checkNumber"
              >
                <b-form-group label="Nº CHECKBOOK">
                  <b-form-input
                    v-mask="'############'"
                    class="form-control"
                    v-model="dataCheckbook.checkNumber"
                    :class="errors[0]"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Status"
              >
                <b-form-group :class="errors[0]" label="STATUS">
                  <v-select
                    v-model="dataCheckbook.statusCheckbook"
                    :options="status"
                    :reduce="(item) => item.id"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="text-center form-group col-md-6">
              <div :class="statusCheck ? '' : 'border-danger'">
                <b-form-checkbox
                  label="CHECKBOOK TYPE"
                  v-for="option in type"
                  :key="option.id"
                  v-model="dataCheckbook.typeCheckbook"
                  :value="option.id"
                  :disabled="isUpdate"
                  name="flavourMaterial"
                  class="col-4 materialCheckbox"
                  inline
                  @change="changeCheck"
                  >{{ option.label }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="dataCheckbook.typeCheckbook == 2 ? 'required' : ''"
                name="startNumber"
              >
                <b-form-group
                  label="START NUMBER"
                  v-if="dataCheckbook.typeCheckbook == 2"
                >
                  <b-form-input
                    v-mask="'############'"
                    class="form-control"
                    v-model="dataCheckbook.startNumber"
                    :class="errors[0]"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="dataCheckbook.typeCheckbook == 2 ? 'required' : ''"
                name="endNumber"
              >
                <b-form-group
                  label="END NUMBER"
                  v-if="dataCheckbook.typeCheckbook == 2"
                >
                  <b-form-input
                    v-mask="'############'"
                    class="form-control"
                    v-model="dataCheckbook.endNumber"
                    :class="errors[0]"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button class="" @click="cancel()">CANCEL</b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            @click="
              isUpdate == true
                ? updateCheckbooks(idCheckbook)
                : createCheckbooks()
            "
            ><feather-icon icon="SaveIcon" class="mr-1" />
            {{ isUpdate == true ? "UPDATE" : "SAVE" }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import vSelect from "vue-select";
import VueMask from "v-mask";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueMask,
  },
  props: {
    dataCheckbooks: {
      type: Number,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    idCheckbook: {
      type: Number,
    },
  },
  data() {
    return {
      status: [
        { id: 1, label: "Active" },
        { id: 2, label: "Inactive" },
      ],
      type: [
        { id: 1, label: "Virtual" },
        { id: 2, label: "Physical" },
      ],
      dataCheckbook: {
        checkNumber: "",
        statusCheckbook: "",
        typeCheckbook: 0,
        startNumber: "",
        endNumber: "",
      },
      statusCheck: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-add-checkbooks");
    if (this.isUpdate) {
      this.getCheckbook(this.idCheckbook);
    }
  },

  methods: {
    changeCheck(value) {
      if (value) {
        this.statusCheck = true;
      } else this.statusCheck = false;
    },

    async createCheckbooks() {
      this.dataCheckbook.typeCheckbook == 0
        ? (this.statusCheck = false)
        : (this.statusCheck = true);
      const result = await this.$refs.observer.validate();
      if (result && this.dataCheckbook.typeCheckbook) {
        if (parseInt(this.dataCheckbook.checkNumber) == 0) {
          this.showWarningSwal(
            "THE CHECKBOOK NUMBER CANNOT BE LESS OR EQUAL TO 0"
          );
        } else {
          if (
            parseInt(this.dataCheckbook.startNumber) >
            parseInt(this.dataCheckbook.endNumber)
          ) {
            this.showWarningSwal(
              "THE START NUMBER CANNOT BE GREATER THAN THE END NUMBER"
            );
          } else {
            if (
              parseInt(this.dataCheckbook.startNumber) ==
              parseInt(this.dataCheckbook.endNumber)
            ) {
              this.showWarningSwal(
                "THE START NUMBER CANNOT BE THE SAME AS THE END NUMBER"
              );
            } else {
              try {
                const params = {
                  checkNumber: this.dataCheckbook.checkNumber,
                  statusCheckbook: this.dataCheckbook.statusCheckbook,
                  typeCheckbook: this.dataCheckbook.typeCheckbook,
                  startNumber: this.dataCheckbook.startNumber,
                  endNumber: this.dataCheckbook.endNumber,
                  idBankAccount: this.dataCheckbooks,
                  user_id: this.currentUser.user_id,
                };
                const resultState = await this.showConfirmSwal();

                if (resultState.isConfirmed) {
                  const resul = await BankAccountsService.createCheckbook(
                    params
                  );
                  if (resul.status == 200) {
                    this.showSuccessSwal('Success!','The checkbook has been created successfully');
                    this.$emit("reloadCheckbooksTable");
                    this.$emit("closeModalCreationCheckbooks");
                  }
                }
              } catch (error) {
                if (error.response?.data?.error) {
                  this.showInfoSwal(error.response?.data?.error, "Info");
                } else {
                  this.showErrorSwal(error);
                }
              }
            }
          }
        }
      }
    },
    async updateCheckbooks(id_checkbook) {
      if (this.dataCheckbook.typeCheckbook) {
        if (parseInt(this.dataCheckbook.checkNumber) == 0) {
          this.showWarningSwal(
            "THE CHECKBOOK NUMBER CANNOT BE LESS OR EQUAL TO 0"
          );
        } else {
          if (
            parseInt(this.dataCheckbook.startNumber) >
            parseInt(this.dataCheckbook.endNumber)
          ) {
            this.showWarningSwal(
              "THE START NUMBER CANNOT BE GREATER THAN THE END NUMBER"
            );
          } else {
            if (
              parseInt(this.dataCheckbook.startNumber) ==
              parseInt(this.dataCheckbook.endNumber)
            ) {
              this.showWarningSwal(
                "THE START NUMBER CANNOT BE THE SAME AS THE END NUMBER"
              );
            } else {
              try {
                let params = {
                  id_checkbook: id_checkbook,
                  number: this.dataCheckbook.checkNumber,
                  type: this.dataCheckbook.typeCheckbook,
                  range_from: this.dataCheckbook.startNumber,
                  range_to: this.dataCheckbook.endNumber,
                };

                const result = await this.showConfirmSwal();
                if (result.isConfirmed) {
                  this.addPreloader();
                  const { data, status } =
                    await BankAccountsService.updateCheckbook(params);
                  let message = data;
                  if (typeof message == "string") {
                    this.showInfoSwal(message);
                  } else {
                    if (status == 200) {
                      this.showSuccessSwal('Success!','The checkbook has been successfully updated');
                      this.$emit("closeModalCreationCheckbooks");
                      this.$emit("reloadCheckbooksTable");
                    }
                  }
                }
              } catch (error) {
                throw error;
              } finally {
                this.removePreloader();
              }
            }
          }
        }
      }
    },
    async getCheckbook(id_checkbook) {
      try {
        const { data } = await BankAccountsService.getCheckbookById({
          id_checkbook: id_checkbook,
        });
        this.dataCheckbook.checkNumber = data[0].number;
        this.dataCheckbook.statusCheckbook = data[0].status;
        this.dataCheckbook.typeCheckbook = data[0].type;
        if (data[0].range_from) {
          this.dataCheckbook.startNumber = data[0].range_from;
        }
        if (data[0].range_to) {
          this.dataCheckbook.endNumber = data[0].range_to;
        }
      } catch (error) {
        throw error;
      }
    },
    cancel() {
      this.$emit("closeModalCreationCheckbooks");
    },
  },
};
</script>
