// ADVANCED FILTERS TO TABLEBANKACCOUNTS
export default [
  {
    // [0]
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    // [1]
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    // [2]
    type: "input-money",
    margin: true,
    showLabel: true,
    label: "Balance From:",
    placeholder: "Initial Range",
    vMask: "#########",
    cols: 6
  },
  {
    // [3]
    type: "input-money",
    margin: true,
    showLabel: true,
    label: "Balance To:",
    placeholder: "Final Range",
    vMask: "#########",
    cols: 6,
  },
  {
    // [4]
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [
      { value: 1, label: "ACTIVE" },
      { value: 2, label: "INACTIVE" }
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
  },
  {
    // [5]
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [
      { value: 1, label: "AMG" },
      { value: 2, label: "CLIENT" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
  },
];
