<template>
  <div>
    <b-modal
      @hidden="$emit('hidden')"
      ref="modal-freze-balance"
      size="xmd"
      :title="'BALANCE FREZE'"
      body-class="p-0"
      hide-footer
    >
      <b-table
        :fields="fields"
        :items="myProvider"
        show-empty
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(name_client_with_account)="data">
          <div>
            <span>
              {{ data.item.full_name_client }}
            </span>
            <br />
            <p class="text-secondary">
              {{ data.item.account }}
            </p>
          </div>
        </template>
        <template #cell(amount_freze)="data">
          <span> $ {{ data.item.amount_freze | currency }} </span>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>
<script>
import BankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
export default {
  data() {
    return {
      fields: [
        {
          key: "name_client_with_account",
          label: "CLIENTE",
        },
        {
          key: "amount_freze",
          label: "AMOUNT FREZE",
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (value) => {
            return `$ ${this.$options.filters.currency(value)}`; // use the currency filter to format the value as currency
          },
        },
        {
          key: "order_number",
          label: "ORDER NUMBER",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_by",
          label: "CREATED BY",
        },
        {
          key: "created_at",
          label: "CREATED AT",
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (value) => {
            return this.$options.filters.myGlobalDay(value); // use the myGlobalDay filter to format the value as myGlobalDay
          },
        },
      ],
      isBusy: false,
    };
  },
  props: {
    bankAccountId: {
      type: Number,
    },
  },
  methods: {
    // Asynchronous function to get the freeze balance of a bank account
    async myProvider() {
      try {
        // Create an object with the bank account id
        let params = { bank_account_id: this.bankAccountId };
        // Get the freeze balance from the BankAccountsService
        const { data } = await BankAccountsService.getFrezeBalance(params);
        // Return the data or an empty array if no data is found
        return data || [];
      } catch (e) {
        // Log any errors that occur
        console.log(e);
      }
    },
  },
  mounted() {
    this.toggleModal("modal-freze-balance");
  },
};
</script>
