<template>
  <div>
    <b-modal
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-add-bank-account"
      size="lg"
      :title="title + ' BANK ACCOUNT'"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:60"
                name="bankName"
              >
                <b-form-group label="Bank name">
                  <b-form-input
                    autofocus
                    type="text"
                    class="form-control"
                    v-model="dataBankAccount.bankName"
                    :class="errors[0]"
                    :disabled="paymentPending"
                    maxlength="60"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:60"
                name="accountName"
              >
                <b-form-group label="Account name">
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="dataBankAccount.accountName"
                    :class="errors[0]"
                    :disabled="paymentPending"
                    maxlength="60"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>

            <!-- ADD ADDRESS TO BANK ACCOUNTS ~10-05-2023~ -->
            <div class="form-group col-md-12">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-group label="Address">
                  <vue-google-autocomplete
                    id="accountAddress"
                    ref="refAccountAddress"
                    class="form-control"
                    placeholder="Please type your address"
                    country="us"
                    :class="errors[0]"
                    @placechanged="getAddressData"
                    v-model="dataBankAccount.accountAddress"
                  />
                </b-form-group>
              </ValidationProvider>
            </div>
            <!-- END ADDRESS -->
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="routingNumber"
              >
                <b-form-group label="Routing number">
                  <b-form-input
                    v-mask="'############'"
                    :class="errors[0]"
                    v-model="dataBankAccount.routingNumber"
                    :disabled="paymentPending"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="accountNumber"
              >
                <b-form-group label="Account number">
                  <b-form-input
                    v-mask="'############'"
                    v-model="dataBankAccount.accountNumber"
                    :class="errors[0]"
                    :disabled="paymentPending"
                  ></b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <b-form-group label="Balance">
                <money
                  v-bind="money"
                  v-model="dataBankAccount.balance"
                  class="form-control"
                  :disabled="isDisabled"
                ></money>
              </b-form-group>
            </div>
            <div class="form-group col-md-4">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="statusAccount"
              >
                <b-form-group :class="errors[0]" label="Status">
                  <v-select
                    v-model="dataBankAccount.statusAccount"
                    :options="statusAccounts"
                    :reduce="(item) => item.id"
                    :disabled="isDisabled"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-4">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="type"
              >
                <b-form-group :class="errors[0]" label="Account Type">
                  <v-select
                    v-model="dataBankAccount.type"
                    :options="typeAccounts"
                    :reduce="(item) => item.id"
                    :disabled="isDisabled"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button class="" @click="cancel()">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="setAction()"
            ><feather-icon icon="SaveIcon" class="mr-1" />
            {{ textButton }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service"
import vSelect from "vue-select"
import VueMask from "v-mask"
import { Money } from "v-money"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"
// ADD VUE GOOGLE AUTOCOMPLETE FOR ADDRESS INPUT
import VueGoogleAutocomplete from "vue-google-autocomplete"
extend("required", {
  ...required,
  message: "is-invalid",
})

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueMask,
    Money,
    VueGoogleAutocomplete,
  },
  directives: { money: Money },
  props: {
    dataAccount: {
      type: Object,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validateMoney: false,
      statusAccounts: [
        { id: 1, label: "Active" },
        { id: 2, label: "Inactive" },
      ],
      typeAccounts: [
        { id: 1, label: "AMG" },
        { id: 2, label: "Client" },
      ],
      dataBankAccount: {
        bankName: "",
        accountName: "",
        routingNumber: "",
        accountNumber: "",
        accountAddress: "",
        accountSlug: "",
        checkNumber: "",
        balance: "",
        statusAccount: "",
        type: "",
        typeCheckbook: 1,
        id: 0,
      },
      isDisabled: false,
      title: "ADD",
      textButton: "SAVE",
      money: {
        decimal: ",",
        prefix: "$ ",
        thousands: ".",
        precision: 2,
        maxlength: 16,
      },
      paymentPending: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    // Toggle the modal with id "modal-add-bank-account"
    this.toggleModal("modal-add-bank-account")
    // If this is an update, show the bank account, set title to "UPDATE", set textButton to "UPDATE" and disable it
    // let vm = this
    // vm.$refs.refAccountAddress.$el.value = vm.dataAccount.address
    if (this.isUpdate) {
      // console.log(vm.$refs, 'vm.$refs')
      this.showBankAccount()
      this.title = "UPDATE"
      this.textButton = "UPDATE"
      this.isDisabled = true
    }
  },
  async created() {
    // Check if this is an update operation
    if (this.isUpdate) {
      // Get payments pending
      await this.getPaymentsPending()
    }
  },

  methods: {
    getAddressData(addressData, addressSecond) {
      this.dataBankAccount.accountAddress = addressSecond.formatted_address

      const ctx = this
      addressSecond.address_components.filter(function (val) {
        val.types.filter(function (sub) {
          if (sub == "administrative_area_level_1") {
            ctx.dataBankAccount.accountSlug = val.short_name
          }
        })
      })
    },
    // Validation payments pending bank account (Edit)
    // Asynchronous function to get payments pending for a bank account
    async getPaymentsPending() {
      try {
        // Get data and status from BankAccountsService
        const { data, status } =
          await BankAccountsService.getPaymentsPendingBankAccount({
            // Pass in the bank_account_id
            bank_account_id: this.dataAccount.id,
          })
        // If status is 200, set paymentPending to true or false depending on data
        if (status == 200) {
          data ? (this.paymentPending = true) : (this.paymentPending = false)
        }
      } catch (error) {
        // Show error message
        this.showErrorSwal(error)
      }
    },
    // setAction() is a method that will either update an account bank or create a new one depending on the value of this.isUpdate
    setAction() {
      // If this.isUpdate is true, call the updateAccountBank() method
      if (this.isUpdate) {
        this.updateAccountBank()
      }
      // Otherwise, call the createAccount() method
      else {
        this.createAccount()
      }
    },
    // This function is used to create a new bank account
    async createAccount() {
      // Validate the form
      const result = await this.$refs.observer.validate()
      this.validateMoney = true
      // If the form is valid, proceed with creating the account
      if (result) {
        try {
          // Create an object with all the necessary parameters
          const params = {
            bankName: this.dataBankAccount.bankName,
            accountName: this.dataBankAccount.accountName,
            routingNumber: this.dataBankAccount.routingNumber,
            accountNumber: this.dataBankAccount.accountNumber,
            typeCheckbook: this.dataBankAccount.typeCheckbook,
            balance: parseFloat(this.dataBankAccount.balance),
            statusAccount: this.dataBankAccount.statusAccount,
            type: this.dataBankAccount.type,
            user_id: this.currentUser.user_id,
            address: this.dataBankAccount.accountAddress,
            slug: this.dataBankAccount.accountSlug,
          }

          // Show a confirmation swal
          const resultState = await this.showConfirmSwal()

          // If the user confirms, proceed with creating the account
          if (resultState.isConfirmed) {
            this.addPreloader()
            const resul = await BankAccountsService.createBankAccounts(params)
            // If the account was created successfully, show a success swal and emit an event
            if (resul.status == 200) {
              this.showSuccessSwal('Success!', 'The bank account has been created successfully')
              this.$emit("onCreateAccount")
            }
          }
        } catch (error) {
          // If there is an error, show an info or error swal depending on the type of error
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info")
          } else {
            this.showErrorSwal(error)
          }
        } finally {
          this.removePreloader()
        }
      }
    },
    cancel() {
      this.$emit("closeModal")
    },
    // This function is used to show the bank account details
    showBankAccount() {
      this.dataBankAccount.bankName = this.dataAccount.bank_name
      this.dataBankAccount.accountName = this.dataAccount.account_name
      this.dataBankAccount.routingNumber = this.dataAccount.routing_number
      this.dataBankAccount.accountNumber = this.dataAccount.account_number
      this.dataBankAccount.accountAddress = this.dataAccount.address
      this.dataBankAccount.balance = parseFloat(this.dataAccount.balance)
      this.dataBankAccount.statusAccount = this.dataAccount.status_code
      this.dataBankAccount.type = this.dataAccount.type_code
      this.dataBankAccount.id = this.dataAccount.id
    },
    // This function updates the bank account information
    async updateAccountBank() {
      // Validate the form
      const result = await this.$refs.observer.validate()
      if (result) {
        try {
          // Create an object with the new bank account information
          const params = {
            bankName: this.dataBankAccount.bankName,
            accountName: this.dataBankAccount.accountName,
            routingNumber: this.dataBankAccount.routingNumber,
            accountNumber: this.dataBankAccount.accountNumber,
            balance: parseFloat(this.dataBankAccount.balance),
            statusAccount: this.dataBankAccount.statusAccount.id
              ? this.dataBankAccount.statusAccount.id
              : this.dataBankAccount.statusAccount,
            type: this.dataBankAccount.type.id
              ? this.dataBankAccount.type.id
              : this.dataBankAccount.type,
            user_id: this.currentUser.user_id,
            idBankAccount: this.dataBankAccount.id,
            address: this.dataBankAccount.accountAddress,
            slug: this.dataBankAccount.accountSlug,
          }

          // Show a confirmation swal
          const resultState = await this.showConfirmSwal()
          if (resultState.isConfirmed) {
            this.addPreloader()
            // Call the service to update the bank account
            const resul = await BankAccountsService.updateBankAccount(params)

            let message = resul.data

            // Check the response from the service
            if (typeof message == "string") {
              this.showInfoSwal(message)
            } else {
              if (resul.status == 200) {
                this.showSuccessSwal('Success!', 'The bank account has been successfully updated')
                this.$emit("onUpdateAccount")
              }
            }
          }
        } catch (error) {
          // Handle errors
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info")
          } else {
            this.showErrorSwal(error)
          }
        } finally {
          this.removePreloader()
        }
      }
    },
  },

}
</script>
