<template>
  <b-modal
    hide-footer
    size="xmd"
    @hidden="$emit('hidden')"
    ref="modal-tracking-balance"
    title="TRACKING BALANCE"
    body-class="p-0"
    scrollable
  >
    <b-table
      ref="bankAccountsTable"
      :items="myProvider"
      :fields="fields"
      show-empty
      empty-text="There are no records to show"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(balance)="data">
        <span>$ {{ data.item.balance | currency }}</span>
      </template>
      <template #cell(comment)="data">
          <change-sms :SmsData="data.item.comment" />
        </template>
      <template #cell(type)="data">
        <b-badge
          class="w-100"
          pill
          :variant="variantBadgeStatus(data.item.type)"
        >
          {{ data.item.type | myFontCapitalize }}
        </b-badge>
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.item.created_at | myGlobalDay }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import bankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";

export default {
  props: {
    idTrackinBankAccount: {
      type: Number,
    },
  },
  mounted() {
    this.toggleModal("modal-tracking-balance");
  },
  components: {
    ChangeSms,
  },
  data() {
    return {
      fields: [
        {
          key: "account_number",
          label: "ACCOUNT NUMBER",
        },
        {
          key: "account_name",
          label: "ACCOUNT NAME",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "balance",
          label: "OPERATION AMOUNT",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "comment",
          label: "OPERATION COMMENT",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "type",
          label: "OPERATION ACTION",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_by",
          label: "CREATED BY",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      isBusy: false,
    };
  },
  methods: {
    async myProvider() {
      try {
        const params = {
          id: this.idTrackinBankAccount,
        }
        const {data} = await bankAccountsService.trackingBalanceBankAccount(
          params
        );
        // Return the data or an empty array if there is no data
        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    variantBadgeStatus(type) {
      if (type == "Deposit") {
        return "success";
      } else {
        return "secondary";
      }
    },
  },
};
</script>