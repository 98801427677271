// FIELDS OF TABLEBANKACCOUNT
export default[
    {
        // [0]
        key: "account_name",
        label: "ACCOUNT NAME",
        visible: true,
    },
    {
        // [2]
        key: "account_number",
        label: "BANK NAME",
        visible: true,
    },
    {
        key: "address",
        visible: true
    },
    {
        // [3]
        key: "checkbooks_count",
        label: "CHECKBOOKS",
        tdClass:'text-center',
        thClass:'text-center',
        visible: true,
    },
    {
        // [4]
        key: "balance",
        visible: true,
    },
    {
        // [5]
        key: "freezed_amount",
        visible: true,
    },
    {
        // [6]
        key: 'available_balance',
        label: 'Available Balance',
        visible:true
    },
    {
        // [7]
        key: "status",
        tdClass:'text-center',
        thClass:'text-center',
        visible: true,
        sortable: true,
    },
    {
        // [8]
        key: "type",
        tdClass:'text-center',
        thClass:'text-center',
        visible: true,
        sortable: true,
    },
    {
        // [9]
        key: "created_at",
        label: "CREATED AT",
        visible: true,
    },
    {
        // [10]
        key: "deleted_at",
        label: "DELETED AT",
        visible: true
    },
    {
        // [11]
        key: "tracking",
        label: "Tracking",
        tdClass:'text-center',
        thClass:'text-center',
        visible: true,
    },
    {

        // [12]
        key: "actions",
        visible: true,
    }
]